import mainRequest from '../utils/mainRequestUtils';

const isErrorResponse = (response) => {
  return !response.data || response.data.type === 'ERROR';
};

export const UserActionAPI = {
  postUserExperience: async ({ questStepId, userFeedback, recaptchaToken }) => {
    const feedbackResponse = await mainRequest.post(
      '/user-action/user-experience',
      {
        questStepId,
        userFeedback,
      },
      {
        params: { token: recaptchaToken },
      }
    );
    if (isErrorResponse(feedbackResponse)) {
      throw feedbackResponse.data;
    }

    return feedbackResponse.data;
  },
  postUserQuestWallet: async ({ questId, address, recaptchaToken }) => {
    const feedbackResponse = await mainRequest.post(
      '/user-action/user-quest-wallet',
      {
        questId,
        address,
      },
      {
        params: { token: recaptchaToken },
      }
    );

    if (isErrorResponse(feedbackResponse)) {
      throw feedbackResponse.data;
    }

    return feedbackResponse.data;
  },
};
