import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserActionAPI, QuestStepAPI } from '../../api';
import { removeInvalidToken } from '../../helpers/removeInvalidToken';
import mainRequest from '../../utils/mainRequestUtils';

export const guideChatRequest = {
  getGuidePreviews: createAsyncThunk(
    'guide/getGuidePreviews',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/quests-preview');

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  ),
  getAllSteps: createAsyncThunk(
    'guideChat/getAllSteps',
    async function (guideId, { rejectWithValue }) {
      try {
        const response = await mainRequest.get(`/quest-step/find-all/${guideId}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  ),
  nextStep: createAsyncThunk(
    'guideChat/nextStep',
    async function (data, { rejectWithValue, dispatch }) {
      try {
        const response = await mainRequest.post(
          '/quest-step/next',
          JSON.stringify(data) //todo step
        );

        return response.data;
      } catch (error) {
        removeInvalidToken(error, dispatch);

        return rejectWithValue(error.response.data);
      }
    }
  ),
  actionQuiz: createAsyncThunk(
    'guideChat/actionQuiz',
    async function ({ stepId, value, recaptchaToken }, { rejectWithValue }) {
      try {
        const requestData = {
          questStepId: stepId,
          optionId: value,
        };

        const response = await mainRequest.post('/user-action/quiz', requestData, {
          params: {
            token: recaptchaToken,
          },
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  ),
  actionLink: createAsyncThunk(
    'guideChat/actionLink',
    async function ({ stepId, recaptchaToken }, { rejectWithValue }) {
      try {
        const requestData = {
          questStepId: stepId,
        };
        const response = await mainRequest.post('/user-action/link', requestData, {
          params: {
            token: recaptchaToken,
          },
        });

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  ),
  selectSocial: createAsyncThunk(
    'guideChat/selectSocial',
    async function ({ social }, { rejectWithValue }) {
      try {
        const response = await mainRequest.get(`/user/preference/${social}`);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  sendGasAndNext: createAsyncThunk(
    'guideChat/sendGas',
    async function (
      { guideStepId, guideStepActionType, token, guideId },
      { rejectWithValue }
    ) {
      try {
        try {
          await mainRequest.get(`/claim/questId/${guideId}/gas`, {
            params: { token },
          });
          // No need to assign gasResponse, as we're not using it
        } catch (gasError) {
          // catch error here
        }

        const nextStep = await QuestStepAPI.postNext({
          questStepActionType: guideStepActionType,
          questStepId: guideStepId,
        });

        return nextStep;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  checkSubscriptionAndNext: createAsyncThunk(
    'guideChat/checkSubscriptionAndNext',
    async function (
      { guideStepId, guideStepActionType, recaptchaToken },
      { rejectWithValue }
    ) {
      try {
        const subscriptionResponse = await mainRequest.get(
          `/user-action/subscription/${guideStepId}`,
          {
            params: { token: recaptchaToken },
          }
        );
        if (!subscriptionResponse.data || subscriptionResponse.data.type === 'ERROR') {
          throw subscriptionResponse.data;
        }
        const guideStepData = {
          questStepActionType: guideStepActionType,
          questStepId: guideStepId,
        };

        const nextStepResponse = await mainRequest.post(
          '/quest-step/next',
          JSON.stringify(guideStepData)
        );
        if (!nextStepResponse.data) {
          throw nextStepResponse.data;
        }

        return nextStepResponse.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  checkTwitterLikesAndNext: createAsyncThunk(
    'guideChat/checkTwitterLikesAndNext',
    async function (
      { guideStepId, guideStepActionType, recaptchaToken },
      { rejectWithValue }
    ) {
      try {
        const likeResponse = await mainRequest.get(`/user-action/likes/${guideStepId}`, {
          params: { token: recaptchaToken },
        });

        if (!likeResponse.data) {
          throw new Error('Likes request failed');
        }
        const guideStepData = {
          questStepActionType: guideStepActionType,
          questStepId: guideStepId,
        };

        const nextStepResponse = await mainRequest.post(
          '/quest-step/next',
          JSON.stringify(guideStepData)
        );
        if (!nextStepResponse.data) {
          throw new Error('Next step request failed');
        }

        return nextStepResponse.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  ),
  leaveFeedbackAndNext: createAsyncThunk(
    'guideChat/leaveFeedbackAndNext',
    async function (
      { guideStepId, userFeedback, guideStepActionType, recaptchaToken },
      { rejectWithValue }
    ) {
      try {
        await UserActionAPI.postUserExperience({
          questStepId: guideStepId,
          userFeedback,
          recaptchaToken,
        });

        const nextStep = await QuestStepAPI.postNext({
          questStepActionType: guideStepActionType,
          questStepId: guideStepId,
        });

        return nextStep;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  submitAdditionalWalletAndNext: createAsyncThunk(
    'guideChat/submitAdditionalWalletAndNext',
    async function (
      { questId, questStepId, questStepActionType, address, recaptchaToken },
      { rejectWithValue }
    ) {
      try {
        await UserActionAPI.postUserQuestWallet({
          questId,
          address,
          recaptchaToken,
        });

        const nextStep = await QuestStepAPI.postNext({
          questStepActionType,
          questStepId,
        });

        return nextStep;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  getWalletConnectedInfo: createAsyncThunk(
    'guide/getWalletConnectedInfo',
    async function (_, { rejectWithValue }) {
      try {
        const response = await mainRequest.get('/user-action/wallet-connected');

        if (response.data.type === 'ERROR') {
          throw response.data;
        }

        return response.data.type;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
