import mainRequest from '../utils/mainRequestUtils';

export const QuestStepAPI = {
  postNext: async ({ questStepActionType, questStepId }) => {
    const nextStepResponse = await mainRequest.post(
      '/quest-step/next',
      JSON.stringify({
        questStepId,
        questStepActionType,
      })
    );

    if (!nextStepResponse.data) {
      throw new Error('Next step request failed');
    }

    return nextStepResponse.data;
  },
};
