export const GuideStepType = {
  Quiz: 'QUIZ',
  Content: 'CONTENT',
  SocialTask: 'SOCIAL_NETWORK_TASK',
  LinkClick: 'LINK_CLICK',
  TestNet: 'TEST_NET',
  Interaction: 'INTERACTION',
  Final: 'FINAL_STEP',
};

export const InteractionQuestStepTypes = {
  AddTwitter: 'ADD_TWITTER',
  AddTelegram: 'ADD_TELEGRAM',
  AddWallet: 'ADD_WALLET',
  SendGas: 'SEND_GAS',
  SubscribeOnTwitter: 'SUBSCRIBE_ON_TWITTER',
  SubscribeOnTelegram: 'SUBSCRIBE_ON_TELEGRAM',
  LikeTwitterPost: 'LIKE_TWITTER_POST',
  AddSocialNetwork: 'ADD_SOCIAL_NETWORK',
  ReferralLink: 'REFERRAL_LINK',
  LEAVE_FEEDBACK: 'LEAVE_FEEDBACK',
  SUBMIT_ADDITIONAL_WALLET: 'SUBMIT_ADDITIONAL_WALLET',
};

export const FinalMessageTypes = {
  Congrats: 'CONGRATS',
  Reward: 'REWARD',
};

export const ImageTypes = {
  Image: 'IMAGE',
  Sticker: 'STICKER',
};

export const UserGuideStepStatus = {
  NotStarted: 'NOT_STARTED',
  InProgress: 'IN_PROGRESS',
  Done: 'DONE',
  Finish: 'FINISH',
};

export const GuideStepActionTypes = {
  Select: 'SELECT_OPTION',
  Next: 'NEXT',
  Text: 'TEXT',
  Link: 'LINK_CLICK',
  Hello: 'HELLO_PHRASE',
};

export const GuidesStatuses = {
  InProgress: 'IN_PROGRESS',
  Finished: 'FINISHED',
  FinishedClaimed: 'FINISHED_CLAIMED',
  FinishedNotClaimed: 'FINISHED_NOT_CLAIMED',
};

Object.freeze(InteractionQuestStepTypes);
Object.freeze(GuideStepType);
Object.freeze(FinalMessageTypes);
Object.freeze(UserGuideStepStatus);
Object.freeze(GuideStepActionTypes);
Object.freeze(GuidesStatuses);
